<template>
  <!--底部设置-->
  <div class="zw-footer">
    <!--底部内容-->
    <div class="zw-footer-content">
      <ul class="zw-footer-content-first">
        <li style="display: flex;align-items: center;">
          <div style="width: 36px;margin-right: 10px">
            <img src="../assets/img/logo3.png" width="100%" alt="">
          </div>
          <div style="width: 120px">
            <img src="../assets/img/logo2.png" width="100%" alt="">
          </div>

        </li>
        <li>
          <div class="contentSize">免费服务热线</div>
          <div style="font-size: 18px;font-weight: bold">029-89181990</div>
        </li>
        <li>
          <div class="contentSize">联系我们</div>
          <div style="display: flex;flex-direction: column;">
            <span>邮箱：info@zhongway.com</span>
            <span>邮政编码：710065</span>
            <span>公司地址：西安市丈八一路一号汇鑫IBC(A座)806室</span>
          </div>
        </li>
      </ul>
      <ul v-for="(item, index) in footerArr" :key="index" class="zw-footer-content-second">
        <div class="contentSize">{{ item.name }}</div>
        <li
          v-for="(itemChild, indexChild) in item.itemArr"
          :key="indexChild"
          @click="handleProduct(itemChild)"
        >
          <a>{{ itemChild.title }}</a>
        </li>
      </ul>
      <ul class="zw-footer-content-third">
        <li>
          <div style="width: 160px;">
            <img src="../assets/img/ewm.png" width="100%" alt="">
          </div>
          <div style="text-align: center">
            鑫众为官方微信公众号
          </div>
        </li>
      </ul>
    </div>
    <!--底部版权-->
    <div class="zw-footer-copyright">
      <span>版权所有：陕西鑫众为软件有限公司</span>
      <span style="display: flex;align-items: center;padding-left: 20px">
        <img src="../assets/img/bahtb.png" width="16" height="16" alt="">
        <!--        http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002003278-->
        <a
          style="color: #FFFFFF;padding-left: 5px"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002003278"
          rel="noreferrer"
          target="_blank"
        >陕公网安备61019002003278号</a>
      </span>
      <span style="padding-left: 20px">
        <a style="color: #FFFFFF" href="https://beian.miit.gov.cn/" target="_blank">©陕ICP备12000343号-5</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationButtom',
  data() {
    return {
      footerArr: [
        {
          name: '关于鑫众为',
          itemArr: [
            { title: '了解鑫众为' },
            { title: '企业动态' },
            { title: '客户案例' },
            { title: '联系我们' }
          ]
        },
        {
          name: '热门产品',
          itemArr: [
            { title: '人事工资综合管理系统', path: '/product/personnelWage' },
            { title: '工资查询', path: '/product/wageInquiry' },
            { title: '人员台账', path: '/product/personnelLedger' },
            { title: '法检工资', path: '/product/legalInspection' },
            { title: '人力资源综合管理平台', path: '/product/humanResources' }
          ]
        },
        {
          name: '热门产品',
          itemArr: [
            { title: '人脸核身', path: '/product/humanNuclear' },
            { title: '内控系统', path: '/product/preprocess' },
            { title: '电子运维系统', path: '/product/electron' },
            { title: '陕西税务12366短信平台', path: '/product/SMSPlatform' },
            { title: 'BI产品', path: '/product/BIProduct' }
          ]
        }

      ]
    }
  },
  methods: {
    handleProduct(obj) {
      const { href } = this.$router.resolve({
        path: obj.path,
        query: {
          active: '2'
        }
      })
      // eslint-disable-next-line no-undef
      window.open(href, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
